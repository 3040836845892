import React, { forwardRef } from "react";
import clsx from "clsx";

import type {
	PolymorphicComponentPropsWithRef,
	PolymorphicRef,
} from "../../../types/polymorphic-component";
import { Spinner, SpinnerVariant } from "../../progress/Spinner";

/**
 * Specifies a button's color scheme
 */
export enum ButtonVariant {
	Primary = "primary",
	Secondary = "secondary",
	Success = "success",
	InvertedPrimary = "inverted-primary",
	InvertedSecondary = "inverted-secondary",
}

export enum ButtonSize {
	ExtraSmall = "xs",
	Small = "sm",
	Medium = "md",
	Large = "lg",
}

export type ButtonProps<C extends React.ElementType> =
	PolymorphicComponentPropsWithRef<
		C,
		{
			/**
			 * The variant/color scheme of the button.
			 * @default ButtonVariant.Primary
			 */
			variant?: ButtonVariant;

			/**
			 * The size of the button.
			 * @default ButtonSize.Medium
			 */
			size?: ButtonSize;

			/**
			 * Icon to display on left side of button.
			 */
			iconLeft?: React.ReactNode;

			/**
			 * Icon to display on right side of button.
			 */
			iconRight?: React.ReactNode;

			/**
			 * When true, the button will display a loading spinner.
			 * @default false
			 */
			isLoading?: boolean;

			/**
			 * When true, the button will be disabled.
			 * @default false
			 */
			isDisabled?: boolean;

			/**
			 * When true, the button will take up the full width of its container.
			 * @default false
			 */
			fullWidth?: boolean;

			/**
			 * Trigger hover state from group.
			 * @default false
			 */
			groupHover?: boolean;

			/**
			 * When true, the button won't have any x-axis padding.
			 * @default false
			 */
			condensed?: boolean;

			/**
			 * @default "button"
			 */
			type?: "button" | "submit" | "reset";
		}
	>;

type ButtonComponent = <C extends React.ElementType = "button">(
	props: ButtonProps<C>,
) => React.ReactNode;

const spinnerSizeMap = {
	[ButtonSize.ExtraSmall]: 16,
	[ButtonSize.Small]: 16,
	[ButtonSize.Medium]: 24,
	[ButtonSize.Large]: 24,
};

const spinnerVariantMap = {
	[ButtonVariant.Primary]: SpinnerVariant.White,
	[ButtonVariant.Secondary]: SpinnerVariant.White,
	[ButtonVariant.Success]: SpinnerVariant.White,
	[ButtonVariant.InvertedPrimary]: SpinnerVariant.Primary,
	[ButtonVariant.InvertedSecondary]: SpinnerVariant.White,
};

export const Button: ButtonComponent = forwardRef(
	<C extends React.ElementType = "button">(
		{
			as,
			children,
			variant = ButtonVariant.Primary,
			size = ButtonSize.Medium,
			iconLeft,
			iconRight,
			isLoading = false,
			isDisabled = false,
			fullWidth = false,
			groupHover = false,
			condensed = false,
			className,
			type = "button",
			...props
		}: ButtonProps<C>,
		ref?: PolymorphicRef<C>,
	) => {
		const Component = as ?? "button";
		const hasIcon = iconLeft || iconRight;

		const iconClasses = [
			"transition-color duration-250",

			size === ButtonSize.Small && "text-md mx-2",
			size === ButtonSize.Medium && "text-2xl mx-4",
			size === ButtonSize.Large && "text-3xl mx-5",

			// Primary variant changes color of icon to a more muted color
			variant === ButtonVariant.Primary &&
				"text-blue-20 group-hover:text-white group-disabled:text-light-grey-80",
		];

		return (
			<Component
				className={clsx(
					"group relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-center font-bold outline-transparent duration-200 focus:outline focus:outline-1 disabled:cursor-not-allowed",
					"group relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-center font-bold outline-transparent duration-200 focus:outline focus:outline-1 disabled:cursor-not-allowed",
					fullWidth && "w-full",
					size === ButtonSize.ExtraSmall && [
						"text-[14px]",
						"h-9",
						"px-3",
						"gap-1",
					],
					size === ButtonSize.Small && [
						"text-[14px]",
						"h-9",
						!condensed ? ["min-w-[108px]", hasIcon ? "px-5" : "px-5"] : "px-3",
					],
					size === ButtonSize.Medium && [
						"text-[14px]",
						"h-12",
						!condensed ? ["min-w-[150px]", hasIcon ? "px-14" : "px-7"] : "px-3",
					],
					size === ButtonSize.Large && [
						"text-[18px]",
						"h-16",
						!condensed ? ["min-w-[200px]", hasIcon ? "px-20" : "px-8"] : "px-5",
					],
					variant === ButtonVariant.Primary && [
						"bg-button-primary text-button-primary",
						"hover:bg-button-primary-hover",
						"active:bg-button-primary-active",
						"disabled:text-button-primary-disabled disabled:bg-button-primary-disabled",
						"focus:outline-button-primary",
						groupHover && "group-hover:bg-button-primary-hover",
					],
					variant === ButtonVariant.Secondary && [
						"bg-transparent border border-button-secondary text-button-secondary hover:text-button-secondary-hover",
						"hover:bg-button-secondary-hover",
						"active:bg-button-secondary-active",
						"disabled:bg-button-secondary-disabled disabled:text-button-secondary-disabled",
						"focus:outline-button-secondary",
						groupHover && "group-hover:bg-button-secondary-hover",
					],
					variant === ButtonVariant.Success && [
						"bg-button-success text-button-success",
						"hover:bg-button-success-hover",
						"active:bg-button-success-active",
						"disabled:text-button-success-disabled disabled:bg-button-success-disabled",
						"focus:outline-button-success",
						groupHover && "group-hover:bg-button-success-hover",
					],
					variant === ButtonVariant.InvertedPrimary && [
						"bg-button-inverted-primary disabled:bg-button-inverted-primary-disabled hover:bg-button-inverted-primary-hover active:bg-button-inverted-primary-active focus:outline-button-inverted-primary",
						"text-button-inverted-primary disabled:text-button-inverted-primary-disabled hover:text-button-inverted-primary-hover active:text-button-inverted-primary-active",
						"border-button-inverted-primary disabled:border-button-inverted-primary-disabled hover:border-button-inverted-primary-hover active:border-button-inverted-primary-active border",
						groupHover && "group-hover:bg-button-inverted-primary-hover",
					],
					variant === ButtonVariant.InvertedSecondary && [
						"bg-button-inverted-secondary disabled:bg-button-inverted-secondary-disabled hover:bg-button-inverted-secondary-hover active:bg-button-inverted-secondary-active focus:outline-button-inverted-secondary",
						"text-button-inverted-secondary disabled:text-button-inverted-secondary-disabled hover:text-button-inverted-secondary-hover active:text-button-inverted-secondary-active",
						"border-button-inverted-secondary disabled:border-button-inverted-secondary-disabled hover:border-button-inverted-secondary-hover active:border-button-inverted-secondary-active border",
						groupHover && "group-hover:bg-button-inverted-secondary-hover",
					],
					className,
				)}
				disabled={isDisabled || isLoading}
				ref={ref}
				type={type}
				{...props}
			>
				{isLoading ? (
					<Spinner
						variant={spinnerVariantMap[variant]}
						size={spinnerSizeMap[size]}
					/>
				) : (
					<React.Fragment>
						{iconLeft && (
							<span
								className={clsx(
									size !== ButtonSize.ExtraSmall && ["absolute left-0"],
									iconClasses,
								)}
							>
								{iconLeft}
							</span>
						)}
						{children}
						{iconRight && (
							<span
								className={clsx(
									size !== ButtonSize.ExtraSmall && ["absolute right-0"],
									iconClasses,
								)}
							>
								{iconRight}
							</span>
						)}
					</React.Fragment>
				)}
			</Component>
		);
	},
);
